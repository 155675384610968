import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import team1 from '../../assets/images/team/team1.png'
import team2 from '../../assets/images/team/team2.png'
import team3 from '../../assets/images/team/team4.png'

const TeamMember = () => {
    return (
        <section className="scientist-area pt-50 pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="team" /> 
                        Team Members
                    </span>
                    <p>"Teamwork begins by building trust. And the only way to do that is to overcome our need for invulnerability"― Patrick Lencioni.</p>
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-2 col-sm-6">
                        <div className="single-scientist-item-box">
                            <div className="image">
                                <img src={team1} alt="team" />
                            </div>
                            <div className="content">
                                <h3>Dr. Saba Jamal</h3>
                                <span>CEO & Founder</span>
                            </div>
                        </div>
                    </div>
                  
                    <div className="col-lg-2 col-sm-6">
                        <div className="single-scientist-item-box">
                            <div className="image">
                                <img src={team3} alt="team" />
                            </div>
                            <div className="content">
                                <h3>Inam Ul Haq</h3>
                                <span>Chief Information Technology Officer <br />(CITO)</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-scientist-item-box">
                            <div className="image">
                                <img src={team2} alt="team" />
                            </div>
                            <div className="content">
                                <h3>Erum Khalid</h3>
                                <span>Director Business Development <br />(DBD)</span>
                            </div>
                        </div>
                    </div>

                   
                </div>
            </div>
        </section>
    )
}

export default TeamMember;