import React from 'react';
import { Helmet } from 'react-helmet';

const TagsForAll = (props) => {
  return (
    <Helmet>
      <title>{props.pageTitle}</title>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-S2SVRK6LJB"></script>
      <script type="text/javascript">
       {`    window.dataLayer = window.dataLayer || [];
             function gtag(){
               dataLayer.push(arguments);
             }
             gtag('js', new Date());
             gtag('config', 'G-S2SVRK6LJB');`}
      </script>
    </Helmet> 
  )
}

export default TagsForAll;