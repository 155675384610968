import React from 'react'
import img13 from '../../assets/images/main-banner/banner-two/Innovatiive-Digital-Solution.png'
import icon4 from '../../assets/images/icons/icon4.png'
import icon5 from '../../assets/images/icons/icon5.png'
import icon6 from '../../assets/images/icons/24-7-support-2.png'
import icon7 from '../../assets/images/icons/icon7.png'



const MainBanner = () => {

    return (    
        <React.Fragment>

            <div className="banner-section">


                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                        <div className="banner-content about-content">
                            <div className="content" >
                                <h2>Transforming iT with innovation</h2>
                                <p>A company with a mission to transform the world with innovative solutions enabling delivery of quality products independent of the human development index.</p>
                                <ul className="features-list">
                                    <li>
                                        <img src={icon4} alt="banner" />
                                        <h3>10+ Years</h3>
                                        On the market
                                    </li>
                                    <li>
                                        <img src={icon5} alt="banner" />
                                        <h3>75+</h3>
                                        Clients
                                    </li>
                                    <li>
                                        <img style={{width:'50px'}} src={icon6} alt="banner" />
                                        <h3>24/7</h3>
                                        Remote support
                                    </li>
                                    <li>
                                        <img src={icon7} alt="banner" />
                                        <h3>Unlimited</h3>
                                        Users/Workstations
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                        <img src={img13} alt="banner" />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MainBanner