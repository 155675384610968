import React from 'react'
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  {
    strapiServicesOne {
        simpleText
        
        helpText
        image {
          url
        }
        services {
          id
          icon{
            url
        }
          services
        }
    }
    strapiServicesTwo {
        simpleText
        header
        helpText
        image {
            url
        }
        services {
          id
          icon{
            url
        }
          services
        }
    }
  }
`

const OurServices = () => {
    const data = useStaticQuery(query)
    const {
        strapiServicesOne, strapiServicesTwo
    } = data
    
    return (
        <React.Fragment>
            <fieldset>
            <legend style={{width:" 80%"}}><h3> Consulting Services</h3></legend>
            <div className="about-area pb-100">
                <div className="container-fluid">
                
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-12">
                            <div className="about-img">
                                <img src={strapiServicesOne?.image?.url} alt='consulting services banner'/>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-12">
                            
                            <div className="about-content">
                                <div className="content">
                                    <ul className="about-list mb-0">
                                        {strapiServicesOne.services.map(service => (
                                            <li key={service.id}>
                                                <i><img src={service.icon?.url} alt="icon" /> </i>
                                                {service.services}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <legend style={{width:" 80%" ,     margin: "auto",marginBottom:'20px'}}>
            <h3>Application Development</h3></legend>
            
            <div className="our-mission-area pb-50">
                
                <div className="container-fluid">
                
                    <div className="row align-items-center">
                    
                        <div className="col-lg-7 col-md-12">
                            
                            <div className="our-mission-content">
                                
                                <div className="content">

                                    <ul className="our-mission-list mb-0">
                                        {strapiServicesTwo.services.map(service => (
                                            <li key={service.id}>
                                                <i><img src={service.icon?.url} alt="icon" /> </i>
                                                {service.services}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-12">
                            <div className="our-mission-image">
                                <img src={strapiServicesTwo?.image?.url} alt='application development banner'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </fieldset>
        </React.Fragment>
    )
}

export default OurServices;